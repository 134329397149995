var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"py-2",attrs:{"align":"center"}},[_c('v-col',{staticClass:"hidden-md-and-up"},[_c('h1',{staticClass:"title font-weight-medium"},[_vm._v("Logs de acceso")])]),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"text-none ma-1",attrs:{"color":"primary","target":"_blank","loading":_vm.loading,"outlined":"","small":""},on:{"click":function () { return _vm.downloadfile(); }}},[_c('v-icon',{attrs:{"left":"","light":""}},[_vm._v("mdi-download")]),_vm._v(" Exportar registros ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.logs,"items-per-page":-1,"loading":_vm.isLoading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.fullName)+" ")])]}},{key:"item.documentType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.document_type)+" ")])]}},{key:"item.document",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.document)+" ")])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.service)+" ")])]}},{key:"item.management",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.management)+" ")])]}},{key:"item.jobTitle",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.job_title)+" ")])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.user.country)+" ")])]}}],null,true)}),(_vm.paginationPages > 1)?_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.paginationPages,"total-visible":7},on:{"input":function($event){return _vm.listenPagination()}},model:{value:(_vm.filter.page),callback:function ($$v) {_vm.$set(_vm.filter, "page", $$v)},expression:"filter.page"}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }