import { $EventBus } from "@/main";

export default {
  name: "Logs",

  data: () => ({
    loading: false,
    filter: {
      pagination: true,
      page: 1,
    },
    headers: [
      {
        text: "Correo electrónico",
        sortable: false,
        value: "email",
        class: "primary--text",
      },
      {
        text: "Aplicación",
        align: "start",
        sortable: false,
        value: "site",
        class: "primary--text",
      },
      {
        text: "Fecha y hora",
        align: "start",
        sortable: false,
        value: "date",
        class: "primary--text",
      },
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "fullName",
        class: "primary--text",
      },
      {
        text: "Tipo de documento",
        align: "start",
        sortable: false,
        value: "documentType",
        class: "primary--text",
      },
      {
        text: "Documento",
        align: "start",
        sortable: false,
        value: "document",
        class: "primary--text",
      },
      {
        text: "Servicio",
        align: "start",
        sortable: false,
        value: "service",
        class: "primary--text",
      },
      {
        text: "Gerencia",
        align: "start",
        sortable: false,
        value: "management",
        class: "primary--text",
      },
      {
        text: "Posición",
        align: "start",
        sortable: false,
        value: "jobTitle",
        class: "primary--text",
      },
      {
        text: "País",
        align: "start",
        sortable: false,
        value: "country",
        class: "primary--text",
      },
    ],
    currentPage: 1,
    errors: [],
  }),

  computed: {
    logs() {
      return this.$store.getters["logs/getLogs"];
    },
    paginationPages() {
      return this.$store.getters["logs/getPaginationPages"];
    },
    isLoading() {
      return this.$store.state.logs.isLoading;
    },
  },

  created() {
    this.$store.dispatch("logs/getLogs", this.filter);
  },

  methods: {

    listenPagination() {
      this.$store.dispatch("logs/getLogs", this.filter);
    },
    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },
    async downloadfile() {
      try {
        this.loading = true;
				const response = await this.$store.dispatch("logs/exportLogs");
				const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
				link.download = 'logs.xlsx';
				link.style.display = 'none';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} catch (error) {
				this.showSnack('error', error?.response?.data?.message || error.toString());
			} finally {
				this.loading = false;
			}
    }
  },
  components: {},
};
